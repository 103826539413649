html,
body {
    height: 100vh;
    margin: 0;
    font-size: 14px;
}

button {
    cursor: pointer;
}

input {
    outline: none;
}

#root {
    height: 100vh;
    font-family: "Lato", "Open Sans", sans-serif;
}

:root {
    --main_color: hsl(0, 0%, 0%);
    --sec_color: hsl(0, 0%, 100%);
    --header_height: 4rem
}


/* apps */
.app_container {
    min-height: 100vh;
    display: flex;
}

.app_body_container {
    display: flex;
    padding: 0 5vw;
}

.error_label {
    color: red !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
}

.input_container {
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
}

.input_container label {
    padding: 0 0.2rem;
    color: var(--main_color);
    font-weight: 600;
    font-size: 0.9rem;
}

.input_container textarea {
    border-radius: 0.5rem;
    resize: none;
    width: 100%;
    min-height: 70px;
    padding: 0.5rem;
    border: 1px solid lightgray;

    outline: none;
}

.input_container textarea::-webkit-scrollbar {
    width: 0.3rem;
}

.input_container textarea::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #ffffff;
}

.input_container textarea::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--main_color);
}